#sidebar {
  @include respond-to(mobile) {
    width: 100%;
    transform: translateX(-100%); // Initially hidden off-screen
  }

  @include respond-to(tablet) {
    width: 100%;
    transform: translateX(-100%); // Initially hidden off-screen
  }

  @include respond-to(all-desktop) {
    width: 300px;
    transform: translateX(0);
    height: calc(
      100% - var(--computed-safe-area-top) - var(--computed-safe-area-bottom)
    );
  }

  height: 100vh;
  max-height: 100vh;
  will-change: transform;
  transition: transform 0.5s ease;
  position: absolute;
  background: #1a365d;
  white-space: nowrap;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  @include respond-to(all-desktop) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.expanded {
    @include respond-to(mobile) {
      transform: translateX(0); // Slide in from off-screen to 0
    }

    @include respond-to(tablet) {
      transform: translateX(0); // Slide in from off-screen to 0
    }
  }
}

// #sidebar.hidden {
//   transform: translateX(-100%);
// }

/* Close button styling */
#close-button {
  @include respond-to(mobile-and-tablet) {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 28px;
    color: white;
    cursor: pointer;
    z-index: 100;
    background: transparent;
    border: none;
  }
  @include respond-to(all-desktop) {
    display: none;
  }
}

#toptab {
  width: 8em;
  height: 12em;
  background: #1689fc; /* Blue background */
  border-radius: 0px 0px 50px 50px; /* Rounded top corners */
  position: relative; /* Keep it positioned in the normal flow */
  top: 0; /* Align it at the very top */
  margin-bottom: 20px; /* Optional: space between top tab and other content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
}

#desktop-toptab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;
}

.spacer {
  flex: 1;
}

.circle {
  width: 80%;
  aspect-ratio: 1 / 1;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20; /* Ensure the circle is above other elements */
}

.circle-icon {
  width: 90%;
  height: auto;
}

#sidebar-body {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

#sidebar-title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 20px;
}

#sidebar-title span {
  padding-top: 6px;
}

#sidebar-content {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
  overflow-y: auto;
}

.link-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.link-content {
  @include respond-to(mobile-and-tablet) {
    width: 50%;
  }

  @include respond-to(all-desktop) {
    width: 90%;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
}

.selected-link-content {
  @include respond-to(mobile-and-tablet) {
    width: 50%;
  }

  @include respond-to(all-desktop) {
    width: 90%;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;

  border-radius: 10px;
  background: rgba(0, 0, 0, 0.29);
}

.feature-link-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.link-content span {
  padding-left: 8px;
}

.link-content img {
  height: 1em;
  aspect-ratio: 1 / 1;
}

.selected-link-content span {
  padding-left: 8px;
}

.selected-link-content img {
  height: 1em;
  aspect-ratio: 1 / 1;
}

.sub-menu-item {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

.selected-sub-menu-item {
  color: #379bff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

.content-buffer {
  flex-direction: column;
  flex: 1;
  height: 100%;
}

#sidebar-footer {
  height: 4vh;
}

.feature-entry {
  height: 200px;
}

.sub-feature-parent-container {
  margin-top: 8px;
}

.sub-feature-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
  box-sizing: border-box;
  // margin-left: 16px;
}

.bullet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.bullet {
  width: 10px;
  height: 10px;
  background-color: #1689fc;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.hash-line {
  border-left: 2px dotted rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 10px;
  bottom: -20px;
  z-index: 1;
}

.link-content img {
  height: 1.2em;
}

.sidebar-separator {
  width: 90%;

  border-top: 0.5px solid #fff;
}
