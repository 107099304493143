app-footer {
  @include respond-to(mobile-and-tablet) {
    height: 7vh;
    width: 100%;
    flex-shrink: 0;
    background-color: rgb(26, 54, 93);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    justify-content: center;
    z-index: 2;
  }
}

app-header {
  @include respond-to(mobile-and-tablet) {
    height: 12%;
    width: 100%;
    flex-shrink: 0;
    z-index: 0;
    display: flex;
    justify-content: space-around;
  }
}

button,
a,
[role="button"],
.clickable {
  cursor: pointer !important;
}

.clickable {
  cursor: pointer !important;
}
