app-root {
  display: flex;
  flex: 1;
  flex-shrink: 0;
}

#app-root {
  display: flex;
  flex: 1;
}

app-react-native-bundle {
  display: flex;
  flex: 1;

  @include respond-to(all-desktop) {
    // make room for sidebar, fixed width
    @include respond-to(all-desktop) {
      padding-left: 300px;
    }
    box-sizing: border-box;
  }
}

#react-native-root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#react-native-body {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.react-native-header-background {
  background-color: "white";
}

body.react-native-header-background::before {
  content: "";
  position: absolute;
  height: calc(12vh + var(--computed-safe-area-top));
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(26, 54, 93);
}

body.react-native-footer-background::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  height: calc(7vh + var(--computed-safe-area-bottom));
  padding-bottom: calc(var(--computed-safe-area-bottom));
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgb(26, 54, 93);
}
