app-home {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  // padding: 24px;
  background-color: #f8f9fa;
}

#app-home {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @include respond-to(all-desktop) {
    box-sizing: border-box;
  }
  @include respond-to(all-desktop) {
    padding-left: 300px;
  }
}

#app-home-inner-content {
  display: flex;
  //flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

#home-header {
  @include respond-to(mobile-and-tablet) {
    height: 12vh;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }
}

// #header-mask {
//   @include respond-to(all-desktop) {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background: url("/assets/redesign/home/header-desktop-mask-group.png")
//       no-repeat center center;
//     background-size: cover;
//     pointer-events: none;
//     opacity: 0.7;
//     mix-blend-mode: multiply;
//     z-index: 1;
//   }
// }

#home-body {
  display: flex;
  flex: 1;
  width: 100%;

  flex-direction: column;
  z-index: 0;
  justify-content: center;
  align-items: center;

  @include respond-to(mobile-and-tablet) {
    // 100 - header - footer + amount to overlap
    height: calc(
      100vh - 12vh - 7vh - var(--computed-safe-area-top) -
        var(--computed-safe-area-bottom)
    );
    position: absolute;
    // header height - overlap + top safe area
    top: calc(12vh + var(--computed-safe-area-top));
  }
  @include respond-to(all-desktop) {
    height: 100%;
  }
}

#overlay-div {
  @include respond-to(mobile-and-tablet) {
    // position: absolute;
    // top: 5vh;
    // left: 50%;
    // transform: translateX(-50%);
    height: 20vh;
    aspect-ratio: 2/1;
    // width: 80vw;
    // max-width: $mobile-breakpoint;

    background-image: url("/assets/redesign/home/title-background.png"); /* Add your background image here */
    background-size: contain; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat;

    z-index: 10;
    //margin-top: -10vh;

    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    grid-template-rows: 1fr 2fr;
  }
}

#overlay-background {
  height: 24vh;
  width: 90vw;
}

#home-greeting {
  @include respond-to(mobile-and-tablet) {
    grid-column: 1 / 2; /* First column */
    grid-row: 1 / 2; /* First row */
    align-self: end; /* Align to the start of the row */
    justify-self: start; /* Align to the start of the column */
    padding-left: 30%;
  }
}

#home-title {
  // @include respond-to(mobile-and-tablet) {
  //   grid-column: 1 / 2; /* First column */
  //   grid-row: 2 / 3; /* Second row */
  //   align-self: start; /* Align to the end of the row */
  //   justify-self: start; /* Align to the start of the column */
  //   padding-left: 30%;
  //   padding-top: 16px;
  // }

  @include respond-to(mobile-and-tablet) {
    flex: 1;
    align-self: center;
    align-content: center;
    justify-self: center;
    text-align: center;
  }

  @include respond-to(tablet) {
    padding-left: 60px;
  }
}

// #home-logo {
//   @include respond-to(mobile-and-tablet) {
//     grid-column: 2 / 3; /* Second column */
//     grid-row: 1 / 3; /* Span both rows */
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

#home-logo {
  @include respond-to(mobile-and-tablet) {
    height: 100%;
    width: 70px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  @include respond-to(tablet) {
    width: 130px;
  }
}

#home-logo-img {
  @include respond-to(mobile-and-tablet) {
    width: auto;
    height: 70%;
    // max-height: 70%;
    // margin-top: -1vh;
  }
}

#menu-button {
  @include respond-to(mobile-and-tablet) {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10;
  }
}

#menu-button img {
  @include respond-to(mobile-and-tablet) {
    display: block;
    width: 100%;
    height: auto;
  }
}

// #feature-scrollable-container {
//   display: flex;
//   flex: 1;
//   overflow: scroll;
// }

// #feature-container {
//   @include respond-to(mobile-and-tablet) {
//     display: flex;
//     flex: 1;
//     flex-wrap: wrap;
//     overflow: hidden;
//     // justify-content: center;
//     // align-items: flex-start;
//     // gap: 32px 32px;
//     // padding-left: 8px;
//     // padding-right: 8px;
//   }
// }

#spacer {
  @include respond-to(mobile-and-tablet) {
    display: flex;
    flex: 1;
  }
}

// .feature-tile {
//   @include respond-to(mobile-and-tablet) {
//     height: 10%;
//     width: 45%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     flex-shrink: 0;
//     flex-grow: 0;
//   }
// }

#feature-scrollable-container {
  background-color: #f8f9fa;
  width: 100%;
  @include respond-to(mobile-and-tablet) {
    // 100 - header - foot - overlay - overlap
    height: calc(100vh - 12% - 7%);
  }
  @include respond-to(all-desktop) {
    height: 100%;
  }
  overflow-y: auto;
  overflow-x: hidden;
  // margin: 16px;
  padding: 24px;
  padding-bottom: calc(6vh + 4px);
  box-sizing: border-box;
}

#feature-container {
  background-color: #f8f9fa;
  box-sizing: border-box;
  @include respond-to(all-desktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    padding: 24px;
    justify-content: flex-start;
    background-color: #fff;
    min-width: fit-content;
    width: 100%;
  }

  @include respond-to(mobile-and-tablet) {
    display: grid; /* Make this a grid container */

    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */

    grid-auto-rows: minmax(
      0,
      1fr
    ); /* Ensure rows grow to fit content but don't overflow */
    gap: 16px; /* Space between grid items */
    width: 100%; /* Take full width of the scrollable area */
    box-sizing: border-box; /* Include padding/border in size calculations */
    //max-height: 100%; /* Ensure the grid container does not exceed the parent height */
    min-width: 0;
    min-height: 0;
  }
}

#feature-container {
  background-color: #f8f9fa;
  @include respond-to(tablet) {
    grid-template-columns: repeat(3, 1fr); /* Two equal-width columns */
  }
}

.feature-tile {
  @include respond-to(all-desktop) {
    min-width: 260px;
    max-width: 320px;
  }

  @include respond-to(mobile-and-tablet) {
    flex: 1;
  }

  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); /* Combines shadowColor, shadowOffset, shadowOpacity, and shadowRadius */
}

.item-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px; /* Space between flex items */
}

.item-icon-container {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  /* background-color: #f0f7ff; Uncomment if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px; Uncomment if needed */
}

.item-text {
  flex: 1;
  font-size: 15px;
  font-weight: 400;
  color: #374151;
  letter-spacing: -0.01em;
  margin-bottom: 0 !important;
}

// .feature-tile {
//   display: flex;
//   height: 10vh;
//   background-color: #ffffff; /* Example background color */
//   //padding: 20px; /* Example padding */
//   box-sizing: border-box; /* Include padding in size calculations */
//   border: 1px solid #e1e5f0; /* Example border */
//   border-radius: 10px;
//   text-align: center; /* Center text in each item */
//   //height: auto; /* Allow tile to adjust to content height */
//   min-width: 0;
//   min-height: 0;
//   //padding: 16px;
//   align-items: center;
//   align-content: center;
//   justify-content: center;
// }

.feature-icon-container {
  // @include respond-to(mobile-and-tablet) {
  height: 70%;
  display: flex;
  // flex-shrink: 0;
  // flex-grow: 0;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  @include respond-to(mobile-and-tablet) {
    padding-left: 24px;
  }

  @include respond-to(all-desktop) {
    padding-left: 32px;
  }

  // @include respond-to(mobile-and-tablet) {
  //   padding-right: 32px;
  // }

  //border-radius: 10px;
  //background: #f2f2f2;
  // }
}

.item-icon-container img {
  // @include respond-to(mobile-and-tablet) {
  height: 60%;
  //display: block;
  // }
}

.feature-icon-container img {
  // @include respond-to(mobile-and-tablet) {
  height: 50%;
  //display: block;
  // }
}

#home-icon-label-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  // @include respond-to(mobile-and-tablet) {
  //   align-items: start;
  //   align-content: start;
  // }

  // @include respond-to(all-desktop) {
  align-items: center;
  align-content: center;
  // }

  justify-content: center;
}

#home-icon-label-container p {
  // @include respond-to(mobile-and-tablet) {
  margin: 0;
  //font-size: 16px;
  //text-align: start;
  // }
}

.home-header-background {
  background-color: "white";
}

body {
  position: relative; /* Ensures pseudo-elements are positioned relative to body */
}

body.home-header-background::before {
  @include respond-to(mobile-and-tablet) {
    content: "";
    position: absolute;
    height: calc(12vh + var(--computed-safe-area-top));
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(26, 54, 93);
  }
}

body.home-footer-background::after {
  @include respond-to(mobile-and-tablet) {
    content: "";
    box-sizing: border-box;
    position: absolute;
    height: calc(7vh + var(--computed-safe-area-bottom));
    width: 100%;
    padding-bottom: calc(var(--computed-safe-area-bottom));
    bottom: 0;
    left: 0;
    background-color: rgb(26, 54, 93);
  }
}

#header-name-and-org {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  color: #fff;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-left: 32px;
}

#header-spacer {
  display: flex;
  flex: 1;
}

#desktop-home-header-container {
  @include respond-to(all-desktop) {
    width: 100%;
    height: 25vh;
    padding: 8px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;

    box-sizing: border-box;
    display: flex;
  }
}

#desktop-home-header {
  @include respond-to(all-desktop) {
    position: relative;
    width: 100%;
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 0;
    border-radius: 10px;
    background: rgb(26, 54, 93);
    // background: url("/assets/redesign/home/header-desktop-background.png")
    //   no-repeat center center;
    // background-size: contain;
  }
}

#header-badge {
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: flex-end;
}

#header-badge img {
  max-height: 16vh;
  width: auto;
  object-fit: contain;
  margin-right: 32px;
}
