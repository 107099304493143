#header {
  @include respond-to(mobile-and-tablet) {
    display: flex;
    flex-grow: 1;
  }
}

#title-container {
  @include respond-to(mobile-and-tablet) {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}

.non-title-container {
  @include respond-to(mobile-and-tablet) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70px;
    height: 100%;
  }
}

.fake-right-padding {
  margin-right: 70px;
}

#header-menu-button {
  @include respond-to(mobile-and-tablet) {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
  }
}

#header-menu-button img {
  @include respond-to(mobile-and-tablet) {
    display: block;
    width: 100%;
    height: auto;
  }
}

#header-back-button {
  @include respond-to(mobile-and-tablet) {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
  }
}

#header-back-button img {
  @include respond-to(mobile-and-tablet) {
    display: block;
    width: 100%;
    height: auto;
  }
}
