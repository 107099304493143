@media (max-width: 1024px) {
  app-footer {
    height: 7vh;
    width: 100%;
    flex-shrink: 0;
    background-color: rgb(26, 54, 93);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    justify-content: center;
    z-index: 2;
  }
}

@media (max-width: 1024px) {
  app-header {
    height: 12%;
    width: 100%;
    flex-shrink: 0;
    z-index: 0;
    display: flex;
    justify-content: space-around;
  }
}

button,
a,
[role=button],
.clickable {
  cursor: pointer !important;
}

.clickable {
  cursor: pointer !important;
}

@media (max-width: 1024px) {
  _reset.scss * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

@media (max-width: 1024px) {
  .small-title {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 1024px) {
  .large-title {
    color: #fff;
    font-family: "LEMON MILK";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.6px;
    text-transform: uppercase;
  }
}

@media (max-width: 1024px) {
  .icon-label {
    color: #42485d;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2px !important;
  }
}

@media (max-width: 1024px) {
  .footer-icon-label {
    color: white;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2px !important;
  }
}

@media (max-width: 1024px) {
  .home-icon-label {
    color: #42485d;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (max-width: 1024px) {
  .header-title {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}

@media (max-width: 1024px) {
  app-login {
    display: flex;
    flex: 1;
  }
}

@media (max-width: 1024px) {
  .login-background {
    background: linear-gradient(99deg, #095ad8, #095ad8);
  }
}
@media (min-width: 1025px) {
  .login-background {
    background: linear-gradient(99deg, #095ad8 -29.38%, #67b3fb 97.41%);
  }
}

@media (max-width: 1024px) {
  body.login-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("/assets/redesign/login/login_mask_group.png") no-repeat center center;
    background-size: cover;
    pointer-events: none;
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 1;
  }
}
@media (min-width: 1025px) {
  body.login-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("/assets/redesign/login/login_mask_group_desktop.png") no-repeat center center;
    background-size: cover;
    pointer-events: none;
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 0;
  }
}

app-login {
  display: flex;
  flex: 1;
  height: 100%;
}

#app-login {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.06);
  z-index: 0;
  overflow: hidden;
}

#loginLeftContentBody {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#loginLeftContentBody::before {
  content: "";
  position: absolute;
  top: 35%;
  left: 13%;
  height: 40%;
  aspect-ratio: 1.5/1;
  background: url("/assets/redesign/login/login_faded_MN.png") no-repeat center center;
  background-size: cover;
  pointer-events: none;
  opacity: 0.5;
  mix-blend-mode: multiply;
  z-index: 1;
}

#loginContentBody {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
@media (max-width: 1024px) {
  #loginContentBody {
    max-width: 600px;
  }
}

#loginInnerContentBody {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
@media (max-width: 1024px) {
  #loginInnerContentBody {
    max-width: 600px;
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
  }
}
@media (min-width: 1025px) {
  #loginInnerContentBody {
    height: 70%;
    width: 70%;
    display: flex;
    border-radius: 30px;
    background: #fff;
    z-index: 10 !important;
  }
}

#mnTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media (max-width: 1024px) {
  #mnTitle {
    width: 100%;
    height: 15%;
  }
}
@media (min-width: 1025px) {
  #mnTitle {
    height: 22%;
  }
}

#mnColoredTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media (min-width: 1025px) {
  #mnColoredTitle {
    height: 15%;
  }
}

@media (max-width: 1024px) {
  #mnTitle img {
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: 1025px) {
  #mnTitle img {
    height: 100%;
    max-height: 100%;
    width: auto;
    object-fit: contain;
  }
}

#login-title-container {
  padding-top: 4%;
}

#login-title {
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  #login-title {
    color: #fff;
  }
}
@media (min-width: 1025px) {
  #login-title {
    color: #1689fc;
  }
}

#login-form-container {
  padding-top: 4vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#login-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container {
  width: 100%;
}

.login-input {
  padding: 10px 10px;
}
@media (max-width: 1024px) {
  .login-input {
    width: 100%;
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid white;
    color: white !important;
    font-size: 16px;
    z-index: 10;
  }
}
@media (min-width: 1025px) {
  .login-input {
    width: 100%;
    border: none;
    border-radius: 10px;
    border: 1px solid #e1e5f0;
    background: #fff;
    color: #42475d !important;
    font-size: 16px;
    z-index: 10;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  font-size: 16px;
}
@media (max-width: 1024px) {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
  }
}
@media (min-width: 1025px) {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #42475d !important;
  }
}

@media (max-width: 1024px) {
  .login-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

#mn-login-password {
  margin-top: 8px;
}

@media (max-width: 1024px) {
  .login-input:focus {
    outline: none;
    border-bottom: 2px solid white;
  }
}

@media (max-width: 1024px) {
  #login-password-container {
    padding-top: 2%;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  #login-password-container svg {
    margin-left: -30px;
    cursor: pointer;
    z-index: 200;
  }
}

#login-button {
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(99deg, #095ad8 -29.38%, #67b3fb 97.41%);
  box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.06);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

#reset-button {
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(99deg, #095ad8 -29.38%, #67b3fb 97.41%);
  box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.06);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

#login-button:hover {
  background: linear-gradient(99deg, #074bb5 -29.38%, #67b3fb 97.41%);
}

#login-button-container {
  padding-top: 4%;
  z-index: 10;
  width: 72%;
}

#login-error-container {
  margin-top: 18px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  color: #721c24;
  font-size: 14px;
  text-align: center;
  z-index: 10;
}

#login-error-message {
  margin: 0;
}

#forgot-password-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-top: 4px;
}

#reset-link-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

#login-success-message {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

#login-success-message P {
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  width: 60%;
}
@media (max-width: 1024px) {
  #login-success-message P {
    color: white;
  }
}
@media (min-width: 1025px) {
  #login-success-message P {
    color: #42475d;
  }
}

#forgot-password {
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
@media (max-width: 1024px) {
  #forgot-password {
    color: white;
  }
}
@media (min-width: 1025px) {
  #forgot-password {
    color: #42475d;
  }
}

#reset-link {
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
@media (max-width: 1024px) {
  #reset-link {
    color: white;
  }
}
@media (min-width: 1025px) {
  #reset-link {
    color: #42475d;
  }
}

#reset-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

app-home {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #f8f9fa;
}

#app-home {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
@media (min-width: 1025px) {
  #app-home {
    box-sizing: border-box;
  }
}
@media (min-width: 1025px) {
  #app-home {
    padding-left: 300px;
  }
}

#app-home-inner-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

@media (max-width: 1024px) {
  #home-header {
    height: 12vh;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }
}

#home-body {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  z-index: 0;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  #home-body {
    height: calc(81vh - var(--computed-safe-area-top) - var(--computed-safe-area-bottom));
    position: absolute;
    top: calc(12vh + var(--computed-safe-area-top));
  }
}
@media (min-width: 1025px) {
  #home-body {
    height: 100%;
  }
}

@media (max-width: 1024px) {
  #overlay-div {
    height: 20vh;
    aspect-ratio: 2/1;
    background-image: url("/assets/redesign/home/title-background.png"); /* Add your background image here */
    background-size: contain; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    grid-template-rows: 1fr 2fr;
  }
}

#overlay-background {
  height: 24vh;
  width: 90vw;
}

@media (max-width: 1024px) {
  #home-greeting {
    grid-column: 1/2; /* First column */
    grid-row: 1/2; /* First row */
    align-self: end; /* Align to the start of the row */
    justify-self: start; /* Align to the start of the column */
    padding-left: 30%;
  }
}

@media (max-width: 1024px) {
  #home-title {
    flex: 1;
    align-self: center;
    align-content: center;
    justify-self: center;
    text-align: center;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  #home-title {
    padding-left: 60px;
  }
}

@media (max-width: 1024px) {
  #home-logo {
    height: 100%;
    width: 70px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  #home-logo {
    width: 130px;
  }
}

@media (max-width: 1024px) {
  #home-logo-img {
    width: auto;
    height: 70%;
  }
}

@media (max-width: 1024px) {
  #menu-button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10;
  }
}

@media (max-width: 1024px) {
  #menu-button img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1024px) {
  #spacer {
    display: flex;
    flex: 1;
  }
}

#feature-scrollable-container {
  background-color: #f8f9fa;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  padding-bottom: calc(6vh + 4px);
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  #feature-scrollable-container {
    height: calc(100vh - 12% - 7%);
  }
}
@media (min-width: 1025px) {
  #feature-scrollable-container {
    height: 100%;
  }
}

#feature-container {
  background-color: #f8f9fa;
  box-sizing: border-box;
}
@media (min-width: 1025px) {
  #feature-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    padding: 24px;
    justify-content: flex-start;
    background-color: #fff;
    min-width: fit-content;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  #feature-container {
    display: grid; /* Make this a grid container */
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    grid-auto-rows: minmax(0, 1fr); /* Ensure rows grow to fit content but don't overflow */
    gap: 16px; /* Space between grid items */
    width: 100%; /* Take full width of the scrollable area */
    box-sizing: border-box; /* Include padding/border in size calculations */
    min-width: 0;
    min-height: 0;
  }
}

#feature-container {
  background-color: #f8f9fa;
}
@media (min-width: 601px) and (max-width: 1024px) {
  #feature-container {
    grid-template-columns: repeat(3, 1fr); /* Two equal-width columns */
  }
}

.feature-tile {
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); /* Combines shadowColor, shadowOffset, shadowOpacity, and shadowRadius */
}
@media (min-width: 1025px) {
  .feature-tile {
    min-width: 260px;
    max-width: 320px;
  }
}
@media (max-width: 1024px) {
  .feature-tile {
    flex: 1;
  }
}

.item-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px; /* Space between flex items */
}

.item-icon-container {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  /* background-color: #f0f7ff; Uncomment if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px; Uncomment if needed */
}

.item-text {
  flex: 1;
  font-size: 15px;
  font-weight: 400;
  color: #374151;
  letter-spacing: -0.01em;
  margin-bottom: 0 !important;
}

.feature-icon-container {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .feature-icon-container {
    padding-left: 24px;
  }
}
@media (min-width: 1025px) {
  .feature-icon-container {
    padding-left: 32px;
  }
}

.item-icon-container img {
  height: 60%;
}

.feature-icon-container img {
  height: 50%;
}

#home-icon-label-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

#home-icon-label-container p {
  margin: 0;
}

.home-header-background {
  background-color: "white";
}

body {
  position: relative; /* Ensures pseudo-elements are positioned relative to body */
}

@media (max-width: 1024px) {
  body.home-header-background::before {
    content: "";
    position: absolute;
    height: calc(12vh + var(--computed-safe-area-top));
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(26, 54, 93);
  }
}

@media (max-width: 1024px) {
  body.home-footer-background::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    height: calc(7vh + var(--computed-safe-area-bottom));
    width: 100%;
    padding-bottom: calc(var(--computed-safe-area-bottom));
    bottom: 0;
    left: 0;
    background-color: rgb(26, 54, 93);
  }
}

#header-name-and-org {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 32px;
}

#header-spacer {
  display: flex;
  flex: 1;
}

@media (min-width: 1025px) {
  #desktop-home-header-container {
    width: 100%;
    height: 25vh;
    padding: 8px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    display: flex;
  }
}

@media (min-width: 1025px) {
  #desktop-home-header {
    position: relative;
    width: 100%;
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 0;
    border-radius: 10px;
    background: rgb(26, 54, 93);
  }
}

#header-badge {
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: flex-end;
}

#header-badge img {
  max-height: 16vh;
  width: auto;
  object-fit: contain;
  margin-right: 32px;
}

@media (max-width: 1024px) {
  #footer {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

.footer-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media (max-width: 1024px) {
  .footer-icon {
    padding-top: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .footer-icon {
    width: 3vw;
  }
}

@media (max-width: 1024px) {
  .footer-icon-selected {
    padding-top: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

.footer-icon img {
  width: 90%; /* Make the image scale based on the container's width */
  height: auto; /* Maintain the aspect ratio of the image */
}

.footer-icon-selected img {
  width: 90%; /* Make the image scale based on the container's width */
  height: auto; /* Maintain the aspect ratio of the image */
}

@media (max-width: 1024px) {
  #header {
    display: flex;
    flex-grow: 1;
  }
}

@media (max-width: 1024px) {
  #title-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .non-title-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70px;
    height: 100%;
  }
}

.fake-right-padding {
  margin-right: 70px;
}

@media (max-width: 1024px) {
  #header-menu-button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  #header-menu-button img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1024px) {
  #header-back-button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  #header-back-button img {
    display: block;
    width: 100%;
    height: auto;
  }
}

app-root {
  display: flex;
  flex: 1;
  flex-shrink: 0;
}

#app-root {
  display: flex;
  flex: 1;
}

app-react-native-bundle {
  display: flex;
  flex: 1;
}
@media (min-width: 1025px) {
  app-react-native-bundle {
    box-sizing: border-box;
  }
}
@media (min-width: 1025px) and (min-width: 1025px) {
  app-react-native-bundle {
    padding-left: 300px;
  }
}

#react-native-root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#react-native-body {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.react-native-header-background {
  background-color: "white";
}

body.react-native-header-background::before {
  content: "";
  position: absolute;
  height: calc(12vh + var(--computed-safe-area-top));
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(26, 54, 93);
}

body.react-native-footer-background::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  height: calc(7vh + var(--computed-safe-area-bottom));
  padding-bottom: calc(var(--computed-safe-area-bottom));
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgb(26, 54, 93);
}

#sidebar {
  height: 100vh;
  max-height: 100vh;
  will-change: transform;
  transition: transform 0.5s ease;
  position: absolute;
  background: #1a365d;
  white-space: nowrap;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
@media (max-width: 600px) {
  #sidebar {
    width: 100%;
    transform: translateX(-100%);
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  #sidebar {
    width: 100%;
    transform: translateX(-100%);
  }
}
@media (min-width: 1025px) {
  #sidebar {
    width: 300px;
    transform: translateX(0);
    height: calc(100% - var(--computed-safe-area-top) - var(--computed-safe-area-bottom));
  }
}
@media (min-width: 1025px) {
  #sidebar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 600px) {
  #sidebar.expanded {
    transform: translateX(0);
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  #sidebar.expanded {
    transform: translateX(0);
  }
}

/* Close button styling */
@media (max-width: 1024px) {
  #close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 28px;
    color: white;
    cursor: pointer;
    z-index: 100;
    background: transparent;
    border: none;
  }
}
@media (min-width: 1025px) {
  #close-button {
    display: none;
  }
}

#toptab {
  width: 8em;
  height: 12em;
  background: #1689fc; /* Blue background */
  border-radius: 0px 0px 50px 50px; /* Rounded top corners */
  position: relative; /* Keep it positioned in the normal flow */
  top: 0; /* Align it at the very top */
  margin-bottom: 20px; /* Optional: space between top tab and other content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
}

#desktop-toptab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;
}

.spacer {
  flex: 1;
}

.circle {
  width: 80%;
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20; /* Ensure the circle is above other elements */
}

.circle-icon {
  width: 90%;
  height: auto;
}

#sidebar-body {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

#sidebar-title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 20px;
}

#sidebar-title span {
  padding-top: 6px;
}

#sidebar-content {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
  overflow-y: auto;
}

.link-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.link-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
}
@media (max-width: 1024px) {
  .link-content {
    width: 50%;
  }
}
@media (min-width: 1025px) {
  .link-content {
    width: 90%;
  }
}

.selected-link-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.29);
}
@media (max-width: 1024px) {
  .selected-link-content {
    width: 50%;
  }
}
@media (min-width: 1025px) {
  .selected-link-content {
    width: 90%;
  }
}

.feature-link-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.link-content span {
  padding-left: 8px;
}

.link-content img {
  height: 1em;
  aspect-ratio: 1/1;
}

.selected-link-content span {
  padding-left: 8px;
}

.selected-link-content img {
  height: 1em;
  aspect-ratio: 1/1;
}

.sub-menu-item {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

.selected-sub-menu-item {
  color: #379bff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

.content-buffer {
  flex-direction: column;
  flex: 1;
  height: 100%;
}

#sidebar-footer {
  height: 4vh;
}

.feature-entry {
  height: 200px;
}

.sub-feature-parent-container {
  margin-top: 8px;
}

.sub-feature-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.bullet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.bullet {
  width: 10px;
  height: 10px;
  background-color: #1689fc;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.hash-line {
  border-left: 2px dotted rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 10px;
  bottom: -20px;
  z-index: 1;
}

.link-content img {
  height: 1.2em;
}

.sidebar-separator {
  width: 90%;
  border-top: 0.5px solid #fff;
}