@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile {
    @media (max-width: $mobile-breakpoint) {
      @content;
    }
  } @else if $breakpoint == tablet {
    $tablet-min: $mobile-breakpoint + 1px;
    @media (min-width: $tablet-min) and (max-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $breakpoint == desktop {
    $desktop-min: $tablet-breakpoint + 1px;
    @media (min-width: $desktop-min) and (max-width: $desktop-breakpoint) {
      @content;
    }
  } @else if $breakpoint == large-desktop {
    $large-desktop-min: $desktop-breakpoint + 1px;
    @media (min-width: $large-desktop-min) {
      @content;
    }
  } @else if $breakpoint == mobile-and-tablet {
    @media (max-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $breakpoint == all-desktop {
    $desktop-min: $tablet-breakpoint + 1px;
    @media (min-width: $desktop-min) {
      @content;
    }
  }
}
