.small-title {
  @include respond-to(mobile-and-tablet) {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.large-title {
  @include respond-to(mobile-and-tablet) {
    color: #fff;
    font-family: "LEMON MILK";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.6px;
    text-transform: uppercase;
  }
}

.icon-label {
  @include respond-to(mobile-and-tablet) {
    color: #42485d;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2px !important;
  }
}

.footer-icon-label {
  @include respond-to(mobile-and-tablet) {
    color: white;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2px !important;
  }
}

.home-icon-label {
  @include respond-to(mobile-and-tablet) {
    color: #42485d;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.header-title {
  @include respond-to(mobile-and-tablet) {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}
