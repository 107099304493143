app-login {
  @include respond-to(mobile-and-tablet) {
    display: flex;
    flex: 1;
  }
}

.login-background {
  @include respond-to(mobile-and-tablet) {
    background: linear-gradient(99deg, #095ad8, #095ad8);
  }

  @include respond-to(all-desktop) {
    background: linear-gradient(99deg, #095ad8 -29.38%, #67b3fb 97.41%);
  }
}

body.login-background::before {
  @include respond-to(mobile-and-tablet) {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("/assets/redesign/login/login_mask_group.png") no-repeat
      center center;
    background-size: cover;
    pointer-events: none;
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 1;
  }

  @include respond-to(all-desktop) {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("/assets/redesign/login/login_mask_group_desktop.png")
      no-repeat center center;
    background-size: cover;
    pointer-events: none;
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 0;
  }
}

app-login {
  display: flex;
  flex: 1;
  height: 100%;
}

#app-login {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.06);
  z-index: 0;
  overflow: hidden;
}

#loginLeftContentBody {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#loginLeftContentBody::before {
  content: "";
  position: absolute;
  top: 35%;
  left: 13%;
  height: 40%;
  aspect-ratio: 1.5/1;
  background: url("/assets/redesign/login/login_faded_MN.png") no-repeat center
    center;
  background-size: cover;
  pointer-events: none;
  opacity: 0.5;
  mix-blend-mode: multiply;
  z-index: 1;
}

#loginContentBody {
  @include respond-to(mobile-and-tablet) {
    max-width: $mobile-breakpoint;
  }
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#loginInnerContentBody {
  @include respond-to(mobile-and-tablet) {
    max-width: $mobile-breakpoint;
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
  }

  @include respond-to(all-desktop) {
    height: 70%;
    width: 70%;
    display: flex;
    border-radius: 30px;
    background: #fff;
    z-index: 10 !important;
  }

  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#mnTitle {
  @include respond-to(mobile-and-tablet) {
    width: 100%;
    height: 15%;
  }
  @include respond-to(all-desktop) {
    height: 22%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#mnColoredTitle {
  @include respond-to(all-desktop) {
    height: 15%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#mnTitle img {
  @include respond-to(mobile-and-tablet) {
    height: 100%;
    object-fit: cover;
  }

  @include respond-to(all-desktop) {
    height: 100%;
    max-height: 100%;
    width: auto;
    object-fit: contain;
  }
}

#login-title-container {
  padding-top: 4%;
}

#login-title {
  @include respond-to(mobile-and-tablet) {
    color: #fff;
  }

  @include respond-to(all-desktop) {
    color: #1689fc;
  }
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

#login-form-container {
  padding-top: 4vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#login-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container {
  width: 100%;
}

.login-input {
  padding: 10px 10px;
  @include respond-to(mobile-and-tablet) {
    width: 100%;
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid white;
    color: white !important;
    font-size: 16px;
    z-index: 10;
  }

  @include respond-to(all-desktop) {
    width: 100%;

    border: none;
    border-radius: 10px;
    border: 1px solid #e1e5f0;
    background: #fff;
    color: #42475d !important;
    font-size: 16px;
    z-index: 10;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;

  @include respond-to(mobile-and-tablet) {
    -webkit-text-fill-color: white !important;
  }

  @include respond-to(all-desktop) {
    -webkit-text-fill-color: #42475d !important;
  }

  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  font-size: 16px;
}

.login-input::placeholder {
  @include respond-to(mobile-and-tablet) {
    color: rgba(
      255,
      255,
      255,
      0.5
    ); // Placeholder color in white with reduced opacity
  }
}

#mn-login-password {
  margin-top: 8px;
}

.login-input:focus {
  @include respond-to(mobile-and-tablet) {
    outline: none;
    border-bottom: 2px solid white; // Thicker underline on focus
  }
}

#login-password-container {
  @include respond-to(mobile-and-tablet) {
    padding-top: 2%;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

#login-password-container svg {
  @include respond-to(mobile-and-tablet) {
    margin-left: -30px; // Adjust this value as needed
    cursor: pointer;
    z-index: 200;
  }
}

#login-button {
  // @include respond-to(mobile-and-tablet) {
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(99deg, #095ad8 -29.38%, #67b3fb 97.41%);
  box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.06);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  // }
}

#reset-button {
  // @include respond-to(mobile-and-tablet) {
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(99deg, #095ad8 -29.38%, #67b3fb 97.41%);
  box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.06);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  // }
}

#login-button:hover {
  // @include respond-to(mobile-and-tablet) {
  background: linear-gradient(99deg, #074bb5 -29.38%, #67b3fb 97.41%);
  // }
}

#login-button-container {
  padding-top: 4%;
  z-index: 10;
  width: 72%;
}

#login-error-container {
  margin-top: 18px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  color: #721c24;
  font-size: 14px;
  text-align: center;
  z-index: 10;
}

#login-error-message {
  margin: 0;
}

#forgot-password-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-top: 4px;
}

#reset-link-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

#login-success-message {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

#login-success-message P {
  @include respond-to(mobile-and-tablet) {
    color: white;
  }
  @include respond-to(all-desktop) {
    color: #42475d;
  }

  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  width: 60%;
}

#forgot-password {
  @include respond-to(mobile-and-tablet) {
    color: white;
  }
  @include respond-to(all-desktop) {
    color: #42475d;
  }

  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

#reset-link {
  @include respond-to(mobile-and-tablet) {
    color: white;
  }
  @include respond-to(all-desktop) {
    color: #42475d;
  }

  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

#reset-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
