#footer {
  @include respond-to(mobile-and-tablet) {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

.footer-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.footer-icon {
  @include respond-to(mobile-and-tablet) {
    // height: 6vh;
    //width: 6vw;
    padding-top: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
.footer-icon {
  @include respond-to(tablet) {
    width: 3vw;
  }
}

.footer-icon-selected {
  @include respond-to(mobile-and-tablet) {
    //height: 5vh;
    //width: 6vw;
    padding-top: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

// .footer-icon-selected {
//   @include respond-to(tablet) {
//     width: 3vw;
//   }
// }

.footer-icon img {
  width: 90%; /* Make the image scale based on the container's width */
  height: auto; /* Maintain the aspect ratio of the image */
}

.footer-icon-selected img {
  width: 90%; /* Make the image scale based on the container's width */
  height: auto; /* Maintain the aspect ratio of the image */
}
